import React, { useCallback, useEffect, useState } from 'react'
import Api from '../services/Api'

const KeywordInput = () => {
  const [keywords, setKeywords] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ text: '', type: '' })

  const setErrorMessage = useCallback(text => {
    setMessage({ text, type: 'error' })
  }, [])

  const getFlagKeys = useCallback(async () => {
    setMessage({ text: '', type: '' })

    try {
      const response = await Api.getFlagKeys()
      if (!response.success) {
        setErrorMessage('Failed to retrieve keywords. Please try again.')
        return
      }

      setKeywords(response.data.flagKeys)
    } catch (error) {
      console.error(error)
      setErrorMessage(
        error?.response?.data?.message ||
          'An error occurred while retrieving keywords.'
      )
    }
  }, [setErrorMessage])

  useEffect(() => {
    getFlagKeys()
  }, [getFlagKeys])

  const addKeyword = useCallback(
    e => {
      if ((e.key === 'Enter' || e.key === ',') && inputValue.trim()) {
        e.preventDefault()
        const trimmedValue = inputValue.trim()
        if (!keywords.includes(trimmedValue)) {
          setKeywords(prevKeywords => [...prevKeywords, trimmedValue])
          setInputValue('')
        }
      }
    },
    [inputValue, keywords]
  )

  const removeKeyword = useCallback(indexToRemove => {
    setKeywords(prevKeywords =>
      prevKeywords.filter((_, index) => index !== indexToRemove)
    )
  }, [])

  const saveChanges = useCallback(async () => {
    if (loading) return

    setLoading(true)
    setMessage({ text: '', type: '' })

    if (keywords.length === 0) {
      setErrorMessage('Please add at least one keyword before saving.')
      setLoading(false)
      return
    }

    try {
      const response = await Api.createFlagKeys({
        body: { flagKeys: keywords },
      })

      if (!response.success) {
        setErrorMessage('Failed to save keywords. Please try again.')
        return
      }

      setMessage({ text: 'Keywords saved successfully!', type: 'success' })
    } catch (error) {
      console.error(error)
      setErrorMessage(
        error?.response?.data?.message || 'An error occurred while saving.'
      )
    } finally {
      setLoading(false)
    }
  }, [keywords, loading, setErrorMessage])

  const handleInputChange = useCallback(e => {
    setInputValue(e.target.value)
  }, [])

  return (
    <div className="keyword-input-container">
      <label>
        Enter keyword(s) for automatic flagging. Separate keyword(s) by comma or
        press Enter to add keyword.
      </label>
      <div className="keyword-input-wrapper">
        <div className="keyword-box">
          {keywords.map((keyword, index) => (
            <span key={`${keyword}-${index}`} className="keyword-chip">
              {keyword}
              <button
                className="remove-button"
                onClick={() => removeKeyword(index)}
                type="button"
                aria-label={`Remove ${keyword}`}>
                ×
              </button>
            </span>
          ))}
          <input
            type="text"
            value={inputValue}
            placeholder="Add keywords"
            onChange={handleInputChange}
            onKeyDown={addKeyword}
            aria-label="Add keyword"
          />
        </div>
      </div>
      <button
        className="save-button"
        onClick={saveChanges}
        disabled={loading}
        type="button">
        {loading ? 'Saving...' : 'Save Changes'}
      </button>
      {message.text && (
        <p className={`message ${message.type === 'error' ? 'error' : ''}`}>
          {message.text}
        </p>
      )}
    </div>
  )
}

export default KeywordInput
