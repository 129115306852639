import React from 'react'
import KeywordInput from '../../components/KeywordInput'
import SidePanel from '../../layouts/Sidepanel'

const FlagKeys = () => {
  return (
    <div className="content-moderator">
      <SidePanel />
      <div className="content">
        <div className="datatable-wrapper">
          <div className="datatable-header">
            <div className="datatable-header-label-wrapper">
              <span className="datatable-header-label">
                Manage Flagged Keywords
              </span>
            </div>
            <div className="search-flag-wrapper">
              <div className="manage-flag"></div>
              <div className="search-wrapper">
                {/*<Icons.Search className="search-icon"/>*/}
                {/*<input*/}
                {/*    type="search"*/}
                {/*    placeholder="Search"*/}
                {/*    value={searchValue}*/}
                {/*    onChange={handleInputChange}*/}
                {/*/>*/}
              </div>
            </div>
          </div>
        </div>

        <div className="content-wrapper">
          <KeywordInput />
        </div>
      </div>
    </div>
  )
}

export default FlagKeys
