import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
} from 'firebase/firestore'
import classNames from 'classnames'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'
import moment from 'moment'
import pluralize from 'pluralize'

import Sidepanel from '../../layouts/Sidepanel'
import Spinner from '../../components/Spinner'
import Api from '../../services/Api'
import Icons from '../../assets/icons'
import { currencyFormatter } from '../../utils'

const RiderPayout = () => {
  const db = getFirestore()

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingStats, setIsLoadingStats] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [filters, setFilters] = useState({
    page: 0,
    limit: 10,
    sort: 'date_modified_desc',
    method: null,
    status: 'pending',
  })
  const [payouts, setPayouts] = useState([])
  const [totalPayout, setTotalPayout] = useState(0)
  const navigate = useNavigate()

  const today = moment()

  const renderPayoutMethod = method => {
    switch (method) {
      case 'gcash':
        return 'GCash'
      case 'grabpay':
        return 'GrabPay'
      case 'bank':
        return 'Bank'

      default:
        break
    }
  }

  const columns = React.useMemo(
    () => [
      {
        name: 'Amount',
        selector: 'total',
        sortable: true,
        cell: row =>
          `₱${(row.total ? row.total / 100 : 0).toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })}`,
      },
      {
        name: 'Method',
        selector: 'payout_method',
        cell: row =>
          row.payout_method ? (
            renderPayoutMethod(row.payout_method)
          ) : (
            <span>N/A</span>
          ),
      },
      {
        name: 'Account Name',
        selector: 'account_name',
        sortable: true,
        cell: row => (row.account_name ? row.account_name : <span>N/A</span>),
      },
      {
        name: 'Account Number',
        selector: 'account_number',
        cell: row =>
          row.account_number ? row.account_number : <span>N/A</span>,
      },
      {
        name: 'Date',
        selector: 'date_modified',
        sortable: true,
        cell: row =>
          row.date_modified ? (
            <span>
              {format(row.date_modified?._seconds * 1000, 'MMM dd, yyyy')}
            </span>
          ) : (
            <span>N/A</span>
          ),
      },
      {
        name: 'Status',
        selector: 'status',
        cell: row =>
          row.status === 'paid' ? (
            <span style={{ color: '#00bb94' }}>Released</span>
          ) : (
            <span style={{ color: '#91919c' }}>Pending</span>
          ),
      },
    ],
    []
  )

  const handleOnSort = (column, sort) => {
    setFilters(filters => ({ ...filters, sort: `${column.selector}_${sort}` }))
  }

  const handleOnChangeRowsPerPage = rowsPerPage => {
    setFilters(filters => ({ ...filters, limit: rowsPerPage }))
  }

  const handleOnChangePage = page => {
    setFilters(filters => ({ ...filters, page: page - 1 }))
  }

  const handleOnStatusClick = status => {
    setFilters(filters => ({ ...filters, status }))
  }

  const hanldeOnExportClick = async () => {
    try {
      const response = await fetch(`${Api.domain}/payouts/export/csv`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      try {
        const jsonResponse = await response.body()
        if (!jsonResponse.success) throw new Error(jsonResponse.message)
      } catch {}
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'payouts.csv'
      document.body.appendChild(a)

      a.click()
      a.remove()
    } catch (error) {
      console.error(error)
    }
  }

  const handleOnRowClick = row => {
    if (!row.id) return
    navigate(`/admin/payouts/${row.id}`)
  }

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const { page, limit, sort, method, status } = filters

      const response = await Api.getRiderPayouts({
        page,
        limit,
        sort,
        method,
        status,
      })

      if (!response.success) throw new Error(response.message)
      const { data, total_items } = response

      setPayouts(data)
      setTotalItems(total_items)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }, [filters])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setIsLoadingStats(true)
        const payoutsSnapshot = await getDocs(collection(db, 'payouts'))
        if (!payoutsSnapshot.empty) {
          payoutsSnapshot.forEach(async payout => {
            if (payout.data()?.uid && payout.data()?.status === 'pending') {
              const userRef = doc(db, 'users', payout.data()?.uid)
              const userSnap = await getDoc(userRef)

              if (
                userSnap.exists() &&
                userSnap.data()?.account_type === 'rider'
              ) {
                setTotalPayout(prev => (prev += payout.data()?.total))
              }
            }
          })
        }
        setIsLoadingStats(false)
      } catch (error) {
        console.error(error)
        setIsLoadingStats(false)
      }
    }
    fetchStats()
  }, [])

  return (
    <>
      <div className="payouts">
        <Sidepanel />
        <div className="payout-sidepanel">
          <h2>Payout</h2>

          <div className="payout-date-wrapper">
            <div className="payout-date-container">
              <Icons.Motor className="payout-date-icon" />
              <span className="payout-date-label">
                Beebo Rider Daily Payout
              </span>
            </div>
            <span className="payout-date">{today.format('MMMM D, yyyy')}</span>
          </div>

          {isLoadingStats ? (
            <Spinner type="Oval" color="#ffd400" height={100} width={100} />
          ) : (
            <div className="upcoming-payout-wrapper">
              <span className="upcoming-payout-amount">
                {currencyFormatter.format(totalPayout / 100 || 0)}
              </span>
              <span className="upcoming-payot-label">Total Daily Payout</span>
              <span className="paid-payouts-label">
                {pluralize('Pending payouts', totalItems || 0, true)}
              </span>
            </div>
          )}
        </div>
        <div className="payouts-content">
          <div className="datatable-wrapper">
            <div className="datatable-header">
              <div className="datatables-actions-wrapper">
                <button
                  className="export-button"
                  type="button"
                  onClick={hanldeOnExportClick}>
                  <Icons.Download className="export-icon" />
                  <span className="export-label">Download .CSV</span>
                </button>
              </div>
              <div className="payout-types">
                <div
                  className={classNames('payout-type', {
                    active: filters.status === 'pending',
                  })}
                  onClick={() => handleOnStatusClick('pending')}>
                  <span>Payout Requests</span>
                </div>
                <div
                  className={classNames('payout-type', {
                    active: filters.status === 'paid',
                  })}
                  onClick={() => handleOnStatusClick('paid')}>
                  <span>Payout History</span>
                </div>
              </div>
            </div>
            <DataTable
              data={payouts}
              columns={columns}
              noHeader={true}
              pagination={true}
              paginationTotalRows={totalItems}
              paginationServer={true}
              sortServer={true}
              onSort={handleOnSort}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              onChangeRowsPerPage={handleOnChangeRowsPerPage}
              onChangePage={handleOnChangePage}
              onRowClicked={handleOnRowClick}
              responsive={true}
              fixedHeader={true}
              progressPending={isLoading}
              defaultSortField={'date_modified'}
              defaultSortAsc={false}
              paginationComponentOptions={{
                rowsPerPageText: 'Rows per page',
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RiderPayout
