import { ReactComponent as Archive } from './archive.svg'
import { ReactComponent as Back } from './back.svg'
import { ReactComponent as Calendar } from './calendar.svg'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as Commerce } from './commerce.svg'
import { ReactComponent as Download } from './download.svg'
import { ReactComponent as Logout } from './logout.svg'
import { ReactComponent as MenuAction } from './menu-action.svg'
import { ReactComponent as Motor } from './motor.svg'
import { ReactComponent as Need } from './need.svg'
import { ReactComponent as Payout } from './payout.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Sell } from './sell.svg'
import { ReactComponent as Service } from './service.svg'
import { ReactComponent as Success } from './success.svg'
import { ReactComponent as Summary } from './summary.svg'
import { ReactComponent as Truck } from './truck.svg'
import { ReactComponent as UploadId } from './upload-id.svg'
import { ReactComponent as UserCheck } from './user-check.svg'
import { ReactComponent as Warning } from './warning.svg'

const Icons = {
  Back,
  Calendar,
  Close,
  Commerce,
  Download,
  Logout,
  Need,
  Payout,
  Sell,
  Service,
  Summary,
  UploadId,
  UserCheck,
  Search,
  Warning,
  Archive,
  Truck,
  Motor,
  MenuAction,
  Success,
}

export default Icons
